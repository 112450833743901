import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import NavBtn from '../../UI/navBtn';

const ProjectFernweh = ({ url }) => {
	const data = useStaticQuery(graphql`
		query {
			image: file(relativePath: { eq: "Fernweh/fw_mb.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 255) {
						...GatsbyImageSharpFluid
					}
				}
			}
			imageTwo: file(relativePath: { eq: "Fernweh/fw_dp.png" }) {
				id
				childImageSharp {
					fluid(quality: 100, maxWidth: 325) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const [isItemInView, setisItemInView] = useState(false);

	// Intersection observer that set's the inView state when 15% of the referenced element is hidden/insight.

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true,
		// delay: 100,
	});

	// Handle triggering animations.

	useEffect(() => {
		if (inView) {
			setisItemInView(true);
		}
	}, [isItemInView, inView, setisItemInView]);

	return (
		<Link to={url}>
			<div
				className={isItemInView ? 'project-card' : 'project-card-idle'}
				ref={ref}
			>
				<div className='content-wrapper'>
					<div className='img-container fernweh'>
						<Img
							fluid={data.image.childImageSharp.fluid}
							className='project-img-mobile project-img'
						/>
						<Img
							fluid={data.imageTwo.childImageSharp.fluid}
							className='project-img-desktop project-img'
						/>
					</div>
					<div className='backdrop-fernweh'></div>
					<div className='txt-box'>
						<h3 className='scndry-heading'>Fernweh Schweiz</h3>
						<p className='scndry-text'>
							Fernweh Schweiz is an association that focuses on showing the
							negative environmental impact flying has and at the same time
							illustrating the great sustainable travel options Switzerland has
							to offer.
						</p>
						<NavBtn label={'Take a closer look'} />
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ProjectFernweh;
