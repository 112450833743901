import React from 'react';
import Seo from '../../components/Seo';
import Layout from '../../components/layouts/index';
import FernwehProject from '../../components/cards/projects/Fernweh';
import RosaProject from '../../components/cards/projects/Rosa-olof';
import WotschProject from '../../components/cards/projects/Wotsch';

const Projects = () => {
	return (
		<Layout>
			<Seo title='Projects' />
			<div id='projects-page'>
				<h2 className='hero-heading-smaller'>Projects</h2>
				<div className='projects-container'>
					<WotschProject url={'wotsch'} />
					<RosaProject url={'rosa'} />
					<FernwehProject url={'fernweh'} />
				</div>
			</div>
		</Layout>
	);
};

export default Projects;
