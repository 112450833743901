import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import NavBtn from '../../UI/navBtn';

const ProjectRosa = ({ url }) => {
	const data = useStaticQuery(graphql`
		query {
			image: file(relativePath: { eq: "Rosa-olof/ro_mb.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 255) {
						...GatsbyImageSharpFluid
					}
				}
			}
			imageTwo: file(relativePath: { eq: "Rosa-olof/ro_dp.png" }) {
				id
				childImageSharp {
					fluid(quality: 100, maxWidth: 325) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const [isItemInView, setisItemInView] = useState(false);

	// Intersection observer that set's the inView state when 15% of the referenced element is hidden/insight.

	const { ref, inView } = useInView({
		threshold: 0,
		delay: 150,
	});

	// Handle triggering animations.

	useEffect(() => {
		if (inView) {
			setisItemInView(true);
		}
	}, [isItemInView, inView, setisItemInView]);
	return (
		<Link to={url}>
			<div
				className={
					isItemInView ? 'project-card rosa-olof' : 'project-card-idle'
				}
				ref={ref}
			>
				<div className='content-wrapper'>
					<div className='img-container rosa-olof'>
						<Img
							fluid={data.image.childImageSharp.fluid}
							className='project-img-mobile project-img'
						/>
						<Img
							fluid={data.imageTwo.childImageSharp.fluid}
							className='project-img-desktop project-img'
						/>
					</div>
					<div className='backdrop-rosa-olof'></div>
					<div className='txt-box'>
						<h3 className='scndry-heading'>Rósa-Ólöf</h3>
						<p className='scndry-text'>
							Rósa Ólöf is an author from Reykjavík, Iceland. She has several
							books and articles under her belt. Including the poetry book "Í
							hvítum kjól" and the autobiography "Kæra nafna".
						</p>
						<NavBtn label={'Take a closer look'} />
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ProjectRosa;
