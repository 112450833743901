import React from "react";
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight";

const navBtn = ({ label }) => {
  return (
    <div className="nav-btn">
      {/* <NavLink url={url} label={label} /> */}
      <span className="label">{label}</span>
      <HiOutlineArrowNarrowRight size={24} className="btn-arrow" />
    </div>
  );
};

export default navBtn;
